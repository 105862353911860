import React from 'react'
import Layout from '../components/layout/Layout'

const projects = () => {
    return (
        <Layout>

        </Layout>
    )
}

export default projects
